<template>
  <div>
    <time class="time m-0">
      <span>{{ formatTime(data.created_on) }}</span>
      <span>{{ formatDate(data.created_on) }}</span>
    </time>

    <div class="icon" :style="getTheme(data.contact_method).bg_color">
      <em :class="getTheme(data.contact_method).src" style="color: white"></em>
    </div>
    <div class="label">
      <p class="h6" v-if="data.comments">
        <span v-if="!data._toggled">
          {{ data.comments.substring(0, 60)
          }}{{ data.comments.substring(61) ? "..." : "" }}
        </span>
        <span v-else style="line-height: normal">{{ data.comments }}</span>
        <span>
          <CButton
            v-if="data.comments.substring(61)"
            @click="toggle()"
            class="btn btn-link p-0"
          >
            {{ data._toggled ? "Show less" : "Show more" }}
          </CButton>
        </span>
      </p>
      <p class="pl-3 mb-2" v-if="data.contact_method == 'email'">
        <strong>
          {{ formatName(data.created_user || data.commented_by) }}
        </strong>
        has sent a mail with subject named "<strong>
          {{ data.email_conversation.subject }} </strong
        >" to
        <a
          class="h6"
          href="javascript:void(0)"
          @click="navigateToCandidateDetail(data.candidate_uid)"
        >
          {{ formatName(data.candidate_name) }} </a
        >.
        <CButton class="btn btn-link p-0" @click="showMsgModal(data)">
          <em class="fas fa-eye"></em> View Message
        </CButton>
      </p>
    </div>
    <CModal color="primary" :show.sync="isShowMsgModal">
      <template #header>
        <h5>Detailed Message</h5>
        <button
          aria-label="Close"
          class="close"
          @click="isShowMsgModal = false"
        >
          x
        </button>
      </template>
      <template #footer>
        <CButton color="primary" @click="isShowMsgModal = false">Close</CButton>
      </template>
      <p class="h6">
        <strong>{{ selectedMessage.subject }}</strong>
      </p>
      <p class="pl-3" v-html="selectedMessage.body"></p>
      <span
        class="attachment-item"
        v-for="(attachment, index) in selectedMessage.email_attachment"
        :key="index"
      >
        <a v-if="attachment" @click="downloadFile(attachment)">
          <i class="fa fa-paperclip" aria-hidden="true"> </i
          >{{ attachment.file_name }}
        </a>
      </span>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Communications",
  props: ["data", "moreToggle"],
  data: () => ({
    isShowMsgModal: false,
    selectedMessage: {},
  }),
  methods: {
    ...mapActions(["setCandidateListBackUrl", "downloadAttachment"]),
    formatTime(data) {
      return data ? this.$moment.utc(data).local().format("hh:mm A") : "--";
    },
    formatDate(data) {
      return data ? this.$moment.utc(data).local().format("DD-MMM-YYYY") : "--";
    },
    formatName(str) {
      return str?.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    toggle() {
      this.$emit("moreToggle");
    },
    navigateToCandidateDetail(candidate_uid) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.$router.push(`/candidate/${candidate_uid}`);
    },
    showMsgModal(data) {
      this.selectedMessage = data.email_conversation;
      this.isShowMsgModal = true;
    },
    downloadFile(attachment) {
      let attachment_id = attachment.attachment_id;
      let appendAction = this.downloadAttachment({ attachment_id });
      Promise.all([appendAction]).then((res) => {
        let url = res[0];
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", attachment.file_name);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    getTheme(data) {
      let src = "";
      let color = "";
      switch (data) {
        case "email":
          src = "fas fa-envelope";
          color = "#50d38a";
          break;
        case "whatsapp":
          src = "fas fa-whatsapp";
          color = "#25D366;";
          break;
        case "phone":
          src = "fas fa-phone";
          color = "#B23850;";
          break;
        case "message":
          src = "fas fa-sms";
          color = "#61892F;";
          break;
        case "mail":
          src = "fas fa-mail-bulk";
          color = "#A64AC9;";
          break;
        default:
          src = "fas fa-history";
          color = "#A64AC9;";
      }
      return {
        src: src,
        bg_color: "background-color: " + color,
      };
    },
  },
};
</script>
