<template>
  <div>
    <time class="time m-0">
      <span>{{ formatTime(data.created_on) }}</span>
      <span>{{ formatDate(data.created_on) }}</span>
    </time>

    <div class="icon" style="background-color: blueviolet !important">
      <em class="fas fa-check-to-slot" style="color: white"></em>
    </div>
    <div class="label d-flex justify-content-between align-items-center">
      <div>
        <p class="mb-2" v-if="data.status == 'Applied'">
          <a
            class="h6"
            href="javascript:void(0)"
            @click="navigateToCandidateDetail(data.candidate_uid)"
          >
            {{ formatName(data.candidate_name) }}
          </a>
          <strong> applied </strong>
          for Job
          <strong>
            {{ data.job_display_uid }} | {{ formatName(data.job_title) }}
          </strong>
          <span
            class="super-script-info"
            v-if="isNewlyRegistered(data.candidate_created_on)"
          >
            Newly Registered *
          </span>
        </p>
        <p class="mb-2" v-if="data.status == 'Withdrawn'">
          <a
            class="h6"
            href="javascript:void(0)"
            @click="navigateToCandidateDetail(data.candidate_uid)"
          >
            {{ formatName(data.candidate_name) }}
          </a>
          <strong> withdrew </strong>
          for Job
          <strong>
            {{ data.job_display_uid }} | {{ formatName(data.job_title) }}
          </strong>
          <span
            class="super-script-info"
            v-if="isNewlyRegistered(data.candidate_created_on)"
          >
            Newly Registered *
          </span>
        </p>
        <p class="mb-2" v-if="data.status == 'Rejected'">
          System rejected job application of
          <a
            class="h6"
            href="javascript:void(0)"
            @click="navigateToCandidateDetail(data.candidate_uid)"
          >
            {{ formatName(data.candidate_name) }}
          </a>
          for Job
          <strong>
            {{ data.job_display_uid }} | {{ formatName(data.job_title) }}
          </strong>
          <span
            class="super-script-info"
            v-if="isNewlyRegistered(data.candidate_created_on)"
          >
            Newly Registered *
          </span>
          <span
            class="text-primary cursor-pointer ml-1"
            v-c-tooltip="{
              content: 'Add to Scratch Pad',
              appendToBody: true,
              placement: 'left',
            }"
          >
            <i class="fa-solid fa-circle-plus fs-14" @click="addToScratch"></i>
          </span>
        </p>
      </div>
    </div>
    <ScratchPadModal
      v-if="scratchpad.isShowPopup"
      :todo="scratchpad.todo"
      :isShowPopup="scratchpad.isShowPopup"
      @modalCallBack="scratchPadModalCallBack"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ScratchPadModal from "@/containers/Dashboard/RecruiterDashBoard/ScratchPadModal";

export default {
  name: "JobApplication",
  components: {
    ScratchPadModal,
  },
  props: ["data", "moreToggle"],
  data: () => ({
    selectedStatusDetail: {},
    scratchpad: {
      isShowPopup: false,
      modalColor: "primary",
      modalTitle: "ScratchPad",
      modalContent: "Save item to your scratch pad?",
      buttons: ["No", "Yes"],
      toDo: null,
    },
  }),
  computed: {
    ...mapGetters(["getCustomerId", "getUserId"]),
  },
  methods: {
    ...mapActions(["setCandidateListBackUrl", "postScratchPad"]),
    formatTime(data) {
      return data ? this.$moment.utc(data).local().format("hh:mm A") : "--";
    },
    formatDate(data) {
      return data ? this.$moment.utc(data).local().format("DD-MMM-YYYY") : "--";
    },
    formatName(str) {
      return str?.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    toggle() {
      this.$emit("moreToggle");
    },
    navigateToCandidateDetail(candidate_uid) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.$router.push(`/candidate/${candidate_uid}`);
    },
    isNewlyRegistered(date) {
      return date
        ? this.$moment().local().diff(this.$moment.utc(date).local(), "days") <
            7
        : false;
    },
    addToScratch() {
      const name = `<a href='/candidate/${this.data?.candidate_uid}'>${this.data?.candidate_name} (ID-${this.data?.candidate_display_uid})</a>`;
      const job = `<a href='/job-list-preview/${this.data.job_id}'>Job ${this.data?.job_display_uid} | ${this.data?.job_title}</a>`;
      this.scratchpad.todo = {
        title: `Candidate - ${this.data?.candidate_name} (ID-${this.data?.candidate_display_uid}) for Job ${this.data?.job_display_uid} | ${this.data?.job_title}`,
        description: `<p>Please review candidate ${name} for ${job}</p>`,
        customer_uid: this.getCustomerId,
        user_id: this.getUserId,
      };
      this.scratchpad.isShowPopup = true;
    },
    scratchPadModalCallBack(action, payload) {
      if (action && payload) {
        this.postScratchPad(payload);
      }
      this.scratchpad.isShowPopup = false;
      this.scratchpad.todo = null;
    },
  },
};
</script>
