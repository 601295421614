<template>
  <div>
    <time class="time m-0">
      <span>{{ formatTime(data.created_on) }}</span>
      <span>{{ formatDate(data.created_on) }}</span>
    </time>

    <div class="icon" style="background-color: #dc0050 !important">
      <em class="fas fa-user-md" style="color: white"></em>
    </div>
    <div class="label d-flex justify-content-between align-items-center">
      <div>
        <p class="h6" v-if="data.comments">
          <span v-if="!data._toggled">
            {{ data.comments.substring(0, 60)
            }}{{ data.comments.substring(61) ? "..." : "" }}
          </span>
          <span v-else style="line-height: normal">{{ data.comments }}</span>
          <span>
            <CButton
              v-if="data.comments.substring(61)"
              @click="toggle()"
              class="btn btn-link p-0"
            >
              {{ data._toggled ? "Show less" : "Show more" }}
            </CButton>
          </span>
        </p>
        <p
          class="mb-2"
          v-if="
            data.sub_status == 'Favourite' ||
            data.previous_sub_status == 'Favourite'
          "
        >
          <a
            class="h6"
            href="javascript:void(0)"
            @click="navigateToCandidateDetail(data.candidate_uid)"
          >
            {{ formatName(data.candidate_name) }}
          </a>
          {{ data.sub_status == "Favourite" ? "marked as" : "removed from" }}
          <strong>favourite</strong>
          by
          <strong>
            {{ formatName(data.created_user || data.commented_by) }}
          </strong>
        </p>
        <p class="pl-3 mb-2" v-else>
          <a
            class="h6"
            href="javascript:void(0)"
            @click="navigateToCandidateDetail(data.candidate_uid)"
          >
            {{ formatName(data.candidate_name) }}
          </a>
          moved
          {{ data.previous_status ? `from ` : "" }}
          <strong>
            {{ data.previous_status
            }}{{
              data.previous_sub_status ? `(${data.previous_sub_status}) ` : " "
            }}
          </strong>
          to
          <strong>
            {{ data.status }}{{ data.sub_status ? `(${data.sub_status})` : "" }}
          </strong>
          by
          <strong>
            {{ formatName(data.created_user || data.commented_by) }}
          </strong>
        </p>
      </div>
      <div
        style="min-width: 4%"
        class="cursor-pointer position-relative multi-select-icon-div"
      >
        <em
          class="fas fa-info-circle"
          v-c-tooltip="{
            content: `View Detail`,
            placement: `top`,
          }"
          @click="openDetailedStatus(data)"
        ></em>
      </div>
    </div>
    <CModal
      color="primary"
      :show.sync="isShowStatusModal"
      v-if="isShowStatusModal"
      size="lg"
    >
      <template #header>
        <h5>Detailed View</h5>
        <button
          aria-label="Close"
          class="close"
          @click="isShowStatusModal = false"
        >
          x
        </button>
      </template>
      <template #footer>
        <CButton color="secondary" @click="isShowStatusModal = false">
          Close
        </CButton>
      </template>
      <div>
        <div class="row">
          <div class="col-lg-3"><strong>Job Title</strong></div>
          <span class="col-lg-9 font-weight-bold">
            {{ formatName(selectedStatusDetail.item.job_title) }} (ID:
            {{ selectedStatusDetail.item.job_display_uid }})
          </span>
          <div class="col-lg-3"><strong>Candidate Name</strong></div>
          <span class="col-lg-9 font-weight-bold">
            {{ formatName(selectedStatusDetail.item.candidate_name) }} (ID:
            {{ selectedStatusDetail.item.candidate_display_uid }})
          </span>
          <div class="col-lg-3"><strong>Last Status</strong></div>
          <span class="col-lg-9 font-weight-bold">
            {{ selectedStatusDetail.detail[0].status }}
            {{
              selectedStatusDetail.detail[0].sub_status
                ? `(${selectedStatusDetail.detail[0].sub_status})`
                : ""
            }}
          </span>
        </div>
        <div class="mt-3 row d-flex justify-content-center _comments">
          <div class="col" style="max-height: 400px; overflow: auto">
            <div
              class="card p-3 mb-3"
              v-for="(data, index) in selectedStatusDetail.detail"
              :key="index"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="align-items-center">
                  <p class="font-weight-bold mb-1">
                    <span class="text-primary">
                      {{ formatName(data.created_user) }}
                    </span>
                    <span class="ml-2" style="font-weight: 600">
                      ({{ data.status
                      }}{{ data.sub_status ? ` / ${data.sub_status}` : `` }})
                    </span>
                  </p>
                  <span class="ml-3" style="font-weight: 500">
                    {{ data.comments || "--" }}
                  </span>
                </div>
                <div style="min-width: 20%">
                  <p class="mb-0" style="font-weight: 600">
                    {{ formatTime(data.created_on) }}
                  </p>
                  <small style="font-weight: 600">
                    {{ formatDate(data.created_on) }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "StatusChange",
  props: ["data", "moreToggle"],
  data: () => ({
    isShowStatusModal: false,
    selectedStatusDetail: {},
  }),
  methods: {
    ...mapActions(["fetchAuditStatusChange", "setCandidateListBackUrl"]),
    formatTime(data) {
      return data ? this.$moment.utc(data).local().format("hh:mm A") : "--";
    },
    formatDate(data) {
      return data ? this.$moment.utc(data).local().format("DD-MMM-YYYY") : "--";
    },
    formatName(str) {
      return str?.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    toggle() {
      this.$emit("moreToggle");
    },
    navigateToCandidateDetail(candidate_uid) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.$router.push(`/candidate/${candidate_uid}`);
    },
    openDetailedStatus(item) {
      this.$parent.showLoader = true;
      this.tooltipHide();
      this.selectedStatusDetail.item = item;
      this.fetchAuditStatusChange({
        job_id: item?.job_id,
        candidate_uid: item?.candidate_uid,
      })
        .then((res) => {
          let { data } = res;
          this.selectedStatusDetail.detail = data;
          this.isShowStatusModal = true;
          this.$parent.showLoader = false;
        })
        .catch(() => (this.$parent.showLoader = false));
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
  },
};
</script>
