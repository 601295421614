<template>
  <div class="auditTrail-History has-loading-overlay">
    <LoadingOverlay v-if="isFetchingAuditHistory || showLoader" />
    <CRow class="d-flex justify-content-start">
      <CCol md="4" class="pr-0 mb-3">
        <label class="mb-1">History:</label>
        <Select
          name="filter"
          :value="filter.filter"
          @input="handleChangeSelect"
          :options="options && options['filter'] ? options['filter'] : []"
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol
        md="4"
        class="pr-0 mb-3"
        v-if="filter.filter && filter.filter.code == 'communications'"
      >
        <label class="mb-1">Communication Type:</label>
        <Select
          name="sub_filter"
          :value="filter.sub_filter"
          @input="handleChangeSelect"
          :options="
            options && options['communication_type']
              ? options['communication_type']
              : []
          "
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol
        md="4"
        class="pr-0 mb-3"
        v-if="filter.filter && filter.filter.code == 'comments'"
      >
        <label class="mb-1">Comments Type:</label>
        <Select
          name="sub_filter"
          :value="filter.sub_filter"
          @input="handleChangeSelect"
          :options="
            options && options['comments_type'] ? options['comments_type'] : []
          "
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol
        md="4"
        class="pr-0 mb-3"
        v-if="filter.filter && filter.filter.code == 'jobApplications'"
      >
        <label class="mb-1">Application Type:</label>
        <Select
          name="sub_filter"
          :value="filter.sub_filter"
          @input="handleChangeSelect"
          :options="
            options && options['job_applications_type']
              ? options['job_applications_type']
              : []
          "
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol md="4" class="pr-0 mb-3">
        <label class="mb-1">Date Range:</label>
        <Select
          name="date_range"
          :value="filter.date_range"
          @input="handleChangeSelect"
          :options="
            options && options['date_range'] ? options['date_range'] : []
          "
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol
        md="4"
        class="pr-0 mb-3"
        v-if="filter.date_range && filter.date_range.code == 'custom_date'"
      >
        <label class="mb-1">Custom Date:</label>
        <DatePicker
          name="custom_date"
          :value="filter.custom_date"
          @input="handleDatePickerChange"
          :showTillToday="true"
          :range="true"
          returnType="format"
        />
      </CCol>
    </CRow>
    <CRow
      class="d-flex justify-content-start"
      v-if="filter.filter && filter.filter.code != 'jobCandidateCommentsView'"
    >
      <CCol md="4" class="pr-0 mb-3">
        <label class="mb-1 show-search-label">Search:</label>
        <text-input
          name="searchTerm"
          :value="filter.searchTerm"
          @input="handleInput"
          placeholder="Search Candidates..."
        />
      </CCol>
      <CCol md="8" class="pr-0 mb-3">
        <CButton
          class="btn-primary small mr-3"
          @click="searchTerm(true)"
          :disabled="disableSearch"
        >
          Search
        </CButton>
        <div class="mt-2 filter-details d-inline-block" v-if="search">
          <div class="filter-details-item d-flex vs__selected-options">
            <span>Search Term :</span>
            <div class="m-0 ml-1 vs__selected">
              <span>
                {{ search }}
              </span>
              <button
                type="button"
                aria-label="Deselect option"
                class="vs__deselect"
                @click="searchTerm(false)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                  <path
                    d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>

    <div class="container timeline-scrolling" id="history-info">
      <div class="row">
        <div
          class="col-md-11"
          v-if="
            filter.filter && filter.filter.code == 'jobCandidateCommentsView'
          "
        >
          <ul class="timeline pt-3">
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <StatusChange
                v-if="data.audit_type == 'status_change'"
                :data="data"
                @moreToggle="moreToggle(data, index)"
              />
              <Comments
                v-if="
                  data.audit_type == 'private_comments' ||
                  data.audit_type == 'job_related_comments'
                "
                :data="data"
                @moreToggle="moreToggle(data, index)"
              />
              <Communications
                v-if="data.audit_type == 'communications'"
                :data="data"
                @moreToggle="moreToggle(data, index)"
              />
              <JobApplication
                v-if="data.audit_type == 'job_applications'"
                :data="data"
                @moreToggle="moreToggle(data, index)"
              />
            </li>
          </ul>
          <h5
            class="text-center"
            v-if="!(getAuditHistory && getAuditHistory.length)"
          >
            No Data Found!
          </h5>
        </div>
        <div class="col-md-11" v-else>
          <ul v-if="filter.filter.code == 'statusChange'" class="timeline pt-3">
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <StatusChange
                :data="data"
                @moreToggle="moreToggle(data, index)"
              />
            </li>
          </ul>

          <ul v-if="filter.filter.code == 'comments'" class="timeline pt-3">
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <Comments :data="data" @moreToggle="moreToggle(data, index)" />
            </li>
          </ul>

          <ul
            v-if="filter.filter.code == 'communications'"
            class="timeline pt-3"
          >
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <Communications
                :data="data"
                @moreToggle="moreToggle(data, index)"
              />
            </li>
          </ul>

          <ul
            v-if="filter.filter.code == 'jobApplications'"
            class="timeline pt-3"
          >
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <JobApplication
                :data="data"
                @moreToggle="moreToggle(data, index)"
              />
            </li>
          </ul>

          <h5
            class="text-center"
            v-if="!(getAuditHistory && getAuditHistory.length)"
          >
            No Data Found!
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import TextInput from "@/components/reusable/Fields/TextInput";
import Comments from "./Comments.vue";
import StatusChange from "./StatusChange.vue";
import Communications from "./Communications.vue";
import JobApplication from "./JobApplication.vue";

export default {
  name: "HistoryInfo",
  props: ["jobDetails"],
  components: {
    Select,
    DatePicker,
    TextInput,
    Comments,
    StatusChange,
    Communications,
    JobApplication,
  },
  data: () => ({
    filter: {
      filter: { label: "All", code: "jobCandidateCommentsView" },
      date_range: { label: "Last 7 days", code: 7 },
      sub_filter: null,
    },
    search: "",
    showLoader: false,
  }),
  computed: {
    ...mapGetters([
      "isFetchingAuditHistory",
      "getAuditHistory",
      "getAuditHistoryPagination",
      "getContactMethods",
    ]),
    options() {
      return {
        filter: [
          { label: "All", code: "jobCandidateCommentsView" },
          { label: "Status Changes", code: "statusChange" },
          { label: "Job Application/Withdrawal", code: "jobApplications" },
          { label: "Comments", code: "comments" },
          { label: "Communications", code: "communications" },
        ],
        communication_type: this.getContactMethods || [],
        comments_type: [
          { label: "Private Comments", code: "privateJobComments" },
          { label: "Job Related Comments", code: "comments" },
        ],
        job_applications_type: [
          { label: "All", code: 0 },
          { label: "Applied", code: 78 },
          { label: "Withdrawn", code: 80 },
          { label: "System Rejected", code: 79 },
        ],
        date_range: [
          { label: "Last 7 days", code: 7 },
          { label: "Last 14 days", code: 14 },
          { label: "Last 6 weeks", code: 42 },
          { label: "Last 3 months", code: 91 },
          { label: "Last 6 months", code: 182 },
          { label: "Last 12 months", code: 365 },
          { label: "Custom date", code: "custom_date" },
        ],
      };
    },
    disableSearch() {
      return !(
        ["statusChange", "jobApplications"].includes(this.filter.filter.code) ||
        (this.filter.sub_filter &&
          this.filter.sub_filter?.code != "privateJobComments")
      );
    },
  },
  beforeDestroy() {
    const ele = document.getElementById("history-info");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  watch: {
    "$route.params.id": function (id) {
      this.filter.filter = { label: "Status Changes", code: "statusChange" };
      this.search = "";
      this.filter.searchTerm = "";
      this.fetchJobAuditHistory(false);
    },
  },
  methods: {
    ...mapActions(["fetchAuditHistory", "setCandidateListBackUrl"]),
    ...mapMutations(["RESET_AUDIT_HISTORY"]),
    handleInput(name, value) {
      Vue.set(this.filter, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.filter, name, value);
      if (name == "filter") {
        this.filter.searchTerm = "";
        this.search = "";
        if (value?.code == "communications" || value?.code == "comments") {
          this.filter.sub_filter = null;
          this.$store.commit("RESET_AUDIT_HISTORY");
          return;
        }
        if (value?.code == "jobApplications") {
          this.filter.sub_filter = { label: "All", code: 0 };
        }
      }
      if (name == "sub_filter") {
        if (value?.code == "privateJobComments") {
          this.filter.searchTerm = "";
          this.search = "";
        }
      }
      this.fetchJobAuditHistory(false);
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.filter, name, value);
      this.fetchJobAuditHistory(false);
    },
    searchTerm(search) {
      if (search && this.filter?.searchTerm) {
        this.search = this.filter?.searchTerm;
        this.fetchJobAuditHistory(false);
      } else {
        if (this.search) {
          this.search = "";
          this.filter.searchTerm = "";
          this.fetchJobAuditHistory(false);
        }
        this.search = "";
      }
    },
    fetchJobAuditHistory(pagination) {
      if (this.filter?.date_range?.code != "custom_date") {
        this.filter.custom_date = [
          this.$moment()
            .subtract(this.filter?.date_range?.code, "days")
            .format("YYYY-MM-DD"),
          this.$moment().format("YYYY-MM-DD"),
        ];
      }
      let payload = {
        job_id: this.jobDetails?.job_id,
        serviceUrl: this.filter?.filter?.code,
        pagination: pagination,
        date_field: "created_on",
        from_date: this.filter?.custom_date[0],
        to_date: this.filter?.custom_date[1],
      };
      if (this.search)
        payload = {
          ...payload,
          searchTerm: encodeURIComponent(this.search),
        };
      if (this.filter?.filter?.code == "communications") {
        payload = {
          ...payload,
          contact_method: this.filter?.sub_filter?.code,
        };
      }
      if (
        this.filter?.filter?.code == "jobApplications" &&
        this.filter?.sub_filter?.code
      ) {
        payload = {
          ...payload,
          status_id: this.filter?.sub_filter?.code,
        };
      }
      if (this.filter?.filter?.code == "comments") {
        payload.serviceUrl = this.filter?.sub_filter?.code;
      }
      this.fetchAuditHistory(payload);
    },
    onBodyScroll(e) {
      if (
        this.isFetchingAuditHistory ||
        this.getAuditHistoryPagination?.noMoreData
      )
        return;
      if (
        e.target.scrollHeight - e.target.scrollTop <=
        e.target.clientHeight + 1
      ) {
        this.fetchJobAuditHistory(true);
      }
    },
    moreToggle(data, index) {
      this.$set(this.getAuditHistory[index], "_toggled", !data._toggled);
    },
  },
  mounted() {
    this.fetchJobAuditHistory(false);
    const ele = document.getElementById("history-info");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
};
</script>

<style lang="scss" scoped>
.show-search-label {
  display: none;
}
@media (max-width: 767px) {
  .show-search-label {
    display: block;
  }
}
.card {
  border: none;
  box-shadow: 5px 6px 6px 2px #c2c4c6;
  border-radius: 4px;
}
@media (max-width: 520px) {
  ._comments {
    font-size: 12px;
  }
}
</style>
