<template>
  <div>
    <time class="time m-0">
      <span>{{ formatTime(data.created_on) }}</span>
      <span>{{ formatDate(data.created_on) }}</span>
    </time>

    <div class="icon" style="background-color: #1973e8">
      <em class="fas fa-comment" style="color: white"></em>
    </div>
    <div class="label">
      <p class="h6" v-if="data.comments">
        <span v-if="!data._toggled">
          {{ data.comments.substring(0, 60)
          }}{{ data.comments.substring(61) ? "..." : "" }}
        </span>
        <span v-else style="line-height: normal">{{ data.comments }}</span>
        <span>
          <CButton
            v-if="data.comments.substring(61)"
            @click="toggle()"
            class="btn btn-link p-0"
          >
            {{ data._toggled ? "Show less" : "Show more" }}
          </CButton>
        </span>
      </p>
      <p class="pl-3 mb-2">
        <span style="color: forestgreen; font-weight: 400">
          <strong>
            {{
              data.action_id == 18
                ? "Job Candidate Process"
                : data.action_id == 19
                ? "Job Approval Process"
                : ""
            }}
          </strong>
        </span>
        {{ data.action_id ? "- moved" : "Updated " }}
        {{ data.previous_status ? `from ` : "" }}
        <strong>
          {{ data.previous_status
          }}{{
            data.previous_sub_status ? `(${data.previous_sub_status}) ` : " "
          }}
        </strong>
        <strong
          >{{ data.status ? `to ${data.status}` : ""
          }}{{ data.sub_status ? `(${data.sub_status})` : "" }}</strong
        >
        by
        <strong>
          {{ formatName(data.created_user || data.commented_by) }}
        </strong>
        <span v-if="data.candidate_uid">
          for
          <a
            style="font-weight: 600"
            href="javascript:void(0)"
            @click="navigateToCandidateDetail(data.candidate_uid)"
          >
            {{ formatName(data.candidate_name) }}
          </a> </span
        >.
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Comments",
  props: ["data", "moreToggle"],
  computed: {
    ...mapGetters(["getAuditHistory"]),
  },
  methods: {
    ...mapActions(["setCandidateListBackUrl"]),
    formatTime(data) {
      return data ? this.$moment.utc(data).local().format("hh:mm A") : "--";
    },
    formatDate(data) {
      return data ? this.$moment.utc(data).local().format("DD-MMM-YYYY") : "--";
    },
    formatName(str) {
      return str?.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    toggle() {
      this.$emit("moreToggle");
    },
    navigateToCandidateDetail(candidate_uid) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.$router.push(`/candidate/${candidate_uid}`);
    },
  },
};
</script>
